<template>
  <div class="navBox clearfix" :style="{'background-color':backgroundColor }">
    <div class="logoBox left">
      <img @click.stop="logoRoute" :src="magicLogo" alt="">
    </div>
    <el-menu :default-active="$route.name" @select="menuSelect" mode="horizontal" class="menuBox right" text-color="#c3cad6" active-text-color="#ffffff" background-color="transparent">
      <el-menu-item class="menuItem" v-for="(val,ind) in menuData" :index="val.name" :key="ind+'menu'">
        <span slot="title">{{ val.label }}</span>
        <span :class="{'spanline-active':val.name===$route.name}" class="spanline"></span>
      </el-menu-item>
    </el-menu>

  </div>
</template>

<script>
import { magicLogo, menuData } from '@/assets/js/fixed_data'

export default {
  data() {
    return {
      magicLogo: magicLogo,
      menuData: menuData
    }
  },
  props: {
    backgroundColor: String
  },
  methods: {
    menuSelect(name) {
      if (name !== this.$route.name) {
        this.$router.push({ name: name })
      }
    },
    //logo超链接
    logoRoute() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.navBox {
  position: fixed;
  width: calc(100% - 2.4rem);
  padding: 0 1.2rem;
  height: .6rem;
  min-width: 900px;
  background-color: #041535;
  z-index: 9999;

  .logoBox {
    font-size: 0;
    display: flex;
    align-items: center;
    height: 100%;

    &:hover {
      cursor: pointer;
    }

    img {
      height: 40px;
    }
  }
}

.menuBox {
  -moz-user-select: none;
  -webkit-user-select: none;
  height: 100%;
  display: flex;
  align-items: center;

  .menuItem {
    background-color: transparent !important;
    border-bottom: none !important;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      background-color: #c088c147 !important;
    }

    .spanline {
      display: inline-block;
      width: 16px;
      height: 2px;
      background: #7271FF;
      position: absolute;
      bottom: 11px;
      border-radius: 10px;
      left: calc(50% - 8px);
      transform: rotateY(90deg);
      transition: all .5s;
    }

    &:hover .spanline {
      transform: rotateY(0deg);
    }

    .spanline-active {
      transform: rotateY(0deg);
    }
  }
}

/deep/ .el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
</style>


