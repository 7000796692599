<template>
  <div>
    <m-nav :slideIndex="slideIndex" v-show="$route.name !== 'news'"></m-nav>
    <!--    首屏动画-->
    <iframe v-show="iframeShow" class="iframeBox" scrolling="no" :src="'https://magics.ai/animation/?time='+timeNow" border="0" frameborder="0" ref="contentIframe"></iframe>
    <router-view/>
  </div>
</template>

<script>
import nav from '@/components/nav'

export default {
  name: "index",
  components: {
    'm-nav': nav
  },
  computed: {
    iframeShow() {
      return this.$route.name === "home"
    },
    timeNow() {
      return new Date().getTime()
    }
  },
  watch: {
    $route(to) {
      if (to.name === 'home') {
        this.$refs.contentIframe.contentWindow.postMessage({ type: 'continue' }, '*');
      } else {
        this.$refs.contentIframe.contentWindow.postMessage({ type: 'stop' }, '*');
      }
    },
  },
  data() {
    return {
      slideIndex: 0
    }
  },
  mounted() {
    this.getPlatform()
    window.addEventListener("resize", () => {
      this.getPlatform()
    });
  },
  methods: {
    getPlatform() {
      // let flag = navigator.userAgent.includes(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      let flag = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/gi.test(navigator.userAgent)
      this.$store.commit('set_isMobile', flag)
      let clientWidth = document.body.clientWidth;
      this.$store.commit('set_clientWidth', clientWidth)
      console.log('watch_clientWidth:', clientWidth)
    }
  }
}
</script>

<style scoped>
.iframeBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
</style>
