<template>
  <div class="navBox clearfix" :style="{'background-color':backgroundColor }">
    <div class="logoBox left">
      <img @click.stop="logoRoute" :src="magicLogo" alt="">
    </div>
    <div class="menuBtnBox right">

      <el-dropdown trigger="click" placement="bottom-end" @command="handleCommand">
      <span class="el-dropdown-link" style="font-size: 16px;
    color: #fff;">
         <span style="margin-right:5px; ">Menu</span><i class="el-icon-menu menu-icon" @click="drawer = true"></i>
        <div class="breathe-btn"></div>
      </span>
        <el-dropdown-menu class="menu-drop" slot="dropdown">
          <el-dropdown-item v-for="(val,ind) in menuData" :command="val.name" :key="ind+'menu'" :icon="val.icon">
            {{ val.label }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import { magicLogo,menuData } from '@/assets/js/fixed_data'

export default {
  props: {
    backgroundColor: String
  },
  data() {
    return {
      magicLogo: magicLogo,
      menuData: menuData,
      drawer: false,
    }
  },
  methods: {
    //logo超链接
    logoRoute() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      }
    },
    handleCommand(command) {
      this.$router.push({ name: command })
    },
  }
}
</script>
<style lang="less" scoped>
.breathe-btn {
  position: absolute;
  width: 62px;
  height: 5px;
  border: 1px solid #2b92d4;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
  overflow: hidden;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#6cc3fe), to(#21a1d0));
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-name: breathe;
  -webkit-animation-duration: 2700ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
}

@-webkit-keyframes breathe {
  0% {
    opacity: 0;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    border: 1px solid rgba(59, 235, 235, 1);
    box-shadow: 0 1px 30px rgba(59, 255, 255, 1);
  }
}


.navBox {
  position: fixed;
  width: calc(100% - 48px);
  padding: 0 24px;
  height: 60px;
  background-color: #041535;
  z-index: 9999;

  .logoBox {
    font-size: 0;
    display: flex;
    align-items: center;
    height: 100%;

    &:hover {
      cursor: pointer;
    }

    img {
      height: 35px;
    }
  }
}

.menuBtnBox {
  -moz-user-select: none;
  -webkit-user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.menu-icon {
  font-size: 20px;
  color: #fff;

  &:hover {
    color: #8c939d;
  }
}

.menu-drop {
  background-color: black;
  opacity: 0.8;
  border: 1px solid black;

  .el-dropdown-menu__item {
    color: white !important;
  }
}
</style>


