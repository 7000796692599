<template>
  <div class="contentBox" v-show="navVisible">
    <transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOut" :duration="{enter: 600, leave: 300}">
      <pc-nav v-show="!isMobile && clientWidth>1080" :backgroundColor="backgroundColor"></pc-nav>
    </transition>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" :duration="{enter: 600, leave: 300}">
      <m-nav v-show="isMobile || clientWidth<=1080" :backgroundColor="backgroundColor"></m-nav>
    </transition>
  </div>
</template>

<script>
import mNav from './m-nav'
import nav from './nav'
import { mapGetters } from "vuex";

export default {
  name: "index",
  props: {
    slideIndex: Number
  },
  components: {
    "pc-nav": nav,
    'm-nav': mNav,
  },
  computed: {
    ...mapGetters(['isMobile', 'clientWidth']),
  },
  data() {
    return {
      navVisible: true, // 菜单延迟显示
      backgroundColor: 'transparent',
      scrollPosition: 0,
      isScroll: 0
    }
  },
  mounted() {
    let self = this;
    if (!this.isMobile && this.$route.name === "home") {
      self.navVisible = false
      setTimeout(() => {
        self.navVisible = true;
      }, 3000)
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    // slideIndex() {
    //   this.handleScroll()
    // }
  },
  methods: {
    //添加这个handleScroll方法来获取滚动的位置
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      //   let position = document.getElementById('home_body').offsetTop, height = document.body.clientHeight
      //   console.log(scrollTop,height,position)

      //设置背景颜色的透明读 --兼容问题 safari与edge不支持rgb格式颜色，chrome会把rgb自动转换成rgba
      if (scrollTop) {
        this.backgroundColor = `rgba(4, 21, 53,${ scrollTop / (scrollTop + 33) })`;
      } else if (this.$route.name === 'home' && this.slideIndex !== 0) {
        this.backgroundColor = `rgba(4, 21, 53, 1)`;
      } else {
        this.backgroundColor = 'transparent'
      }

      // 第一屏滑动效果
      if (this.$route.name === 'home') {
        let height = document.body.clientHeight
        console.log(scrollTop, this.scrollPosition)
        if (!this.isScroll) {
          if (scrollTop >= height) {
            this.scrollPosition = height
          } else {
            if (scrollTop > height / 2) {
              if (this.scrollPosition - scrollTop > 30) {
                this.isScroll = true
                document.documentElement.scrollTop -= 50
                const Interval = setInterval(() => {
                  document.documentElement.scrollTop -= 50
                  if (document.documentElement.scrollTop <= 50) {
                    this.scrollPosition = 0
                    document.documentElement.scrollTop = 0
                    window.clearInterval(Interval)
                    this.isScroll = false
                  }
                }, 20)
              } else {
                document.documentElement.scrollTop = height
                this.scrollPosition = height
                // this.isScroll = true
                // document.documentElement.scrollTop += 50
                // const Interval = setInterval(() => {
                //   console.log('朝下')
                //   this.scrollPosition = height
                //   document.documentElement.scrollTop += 50
                //   if (document.documentElement.scrollTop >= height) {
                //     document.documentElement.scrollTop = height
                //     window.clearInterval(Interval)
                //     this.isScroll = false
                //   }
                // }, 20)
              }
            } else {
              if (scrollTop - this.scrollPosition > 30) {
                this.isScroll = true
                document.documentElement.scrollTop += 50
                const Interval = setInterval(() => {
                  console.log('朝下')
                  this.scrollPosition = height
                  document.documentElement.scrollTop += 50
                  if (document.documentElement.scrollTop >= height) {
                    document.documentElement.scrollTop = height
                    window.clearInterval(Interval)
                    this.isScroll = false
                  }
                }, 20)
              } else {
                this.scrollPosition = 0
                document.documentElement.scrollTop = 0
                // this.isScroll = true
                // document.documentElement.scrollTop -= 50
                // const Interval = setInterval(() => {
                //   console.log('朝上')
                //   document.documentElement.scrollTop -= 50
                //   if (document.documentElement.scrollTop <= 50) {
                //     this.scrollPosition = 0
                //     document.documentElement.scrollTop = 0
                //     window.clearInterval(Interval)
                //     this.isScroll = false
                //   }
                // }, 20)
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.contentBox {
  position: relative;
  z-index: 11;
}
</style>
